*
{
    padding: 0;
    margin: 0;
}

body,
html
{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.canvas
{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.canvas.has-cursor-pointer
{
    cursor: pointer;
}
